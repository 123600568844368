import { Route, Routes as ReactRouterRoutes, Navigate } from 'react-router-dom';
import { Suspense, lazy, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { AppRoutes, REACT_APP_MAINTAINANCE } from '~constants';
import PrivateRoutes from './PrivateRoutes';
import { FancyLoader } from '~components/Loader';

import {
    AccountDelete,
    AppRedirect,
    Dashboard,
    DashboardContact,
    DashboardProfile,
    ForgetPassword,
    Homepage,
    Login,
    NewHomepage,
    PaymentDetails,
    Privacy,
    Register,
    SignOutRedirect,
    Terms,
    WriterAIDetector,
    WriterContentgenerator,
    WriterDashboard,
    WriterDashboardWrapper,
    WriterDocument,
    WriterTextHumanizer,
    WriterTool,
} from './LazyRoutes';
import { Maintainance } from '~pages/Maintainance';
const ErrorFallback = ({ error }: Readonly<{ error: Error }>) => (
    <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error?.message}</pre>
    </div>
);

const SuspenseWrapper = ({ children }: Readonly<{ children: ReactNode }>) => (
    <Suspense fallback={<FancyLoader />}>{children}</Suspense>
);

export const Routes = () => {
    if (REACT_APP_MAINTAINANCE === 'true') {
        return <Maintainance />;
    }
    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <ReactRouterRoutes>
                <Route
                    path={AppRoutes.Dashboard}
                    element={
                        <SuspenseWrapper>
                            <Dashboard />
                        </SuspenseWrapper>
                    }
                >
                    <Route
                        path="profile"
                        element={
                            <SuspenseWrapper>
                                <DashboardProfile />
                            </SuspenseWrapper>
                        }
                    />
                    <Route
                        path="contact"
                        element={
                            <SuspenseWrapper>
                                <DashboardContact />
                            </SuspenseWrapper>
                        }
                    />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route
                        path={AppRoutes.WriterSteps}
                        element={<WriterDashboardWrapper />}
                    >
                        <Route
                            index
                            element={
                                <SuspenseWrapper>
                                    <WriterDashboard />
                                </SuspenseWrapper>
                            }
                        />
                        <Route
                            path={AppRoutes.WriterContentgenerator}
                            element={
                                <SuspenseWrapper>
                                    <WriterContentgenerator />
                                </SuspenseWrapper>
                            }
                        />
                        <Route
                            path={AppRoutes.WriterAITextHumanizer}
                            element={
                                <SuspenseWrapper>
                                    <WriterTextHumanizer />
                                </SuspenseWrapper>
                            }
                        />
                        <Route
                            path={AppRoutes.WriterAIDetector}
                            element={
                                <SuspenseWrapper>
                                    <WriterAIDetector />
                                </SuspenseWrapper>
                            }
                        />
                        {/* <Route
                            path={AppRoutes.WriterDocuments}
                            element={
                                <SuspenseWrapper>
                                    <WriterDocument />
                                </SuspenseWrapper>
                            }
                        /> */}
                    </Route>
                </Route>

                <Route
                    path={AppRoutes.Profile}
                    element={<Navigate to={AppRoutes.Dashboard} replace />}
                />
                <Route path="*" element={<Navigate to={AppRoutes.Home} />} />

                <Route
                    path={AppRoutes.Home}
                    element={
                        <SuspenseWrapper>
                            <Homepage />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    path={AppRoutes.WriterTool}
                    element={
                        <SuspenseWrapper>
                            <WriterTool />{' '}
                        </SuspenseWrapper>
                    }
                />
                <Route path={AppRoutes.AlternativePage}>
                    <Route
                        index
                        element={
                            <SuspenseWrapper>
                                <NewHomepage />
                            </SuspenseWrapper>
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <SuspenseWrapper>
                                <NewHomepage />
                            </SuspenseWrapper>
                        }
                    />
                </Route>
                <Route
                    path={AppRoutes.Privacy}
                    element={
                        <SuspenseWrapper>
                            <Privacy />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    path={AppRoutes.Terms}
                    element={
                        <SuspenseWrapper>
                            <Terms />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    path={AppRoutes.Signin_Redirect}
                    element={
                        <SuspenseWrapper>
                            <PaymentDetails />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    path={AppRoutes.Mobile_Checkout_Redirect}
                    element={
                        <SuspenseWrapper>
                            <PaymentDetails />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    path={AppRoutes.Signout_Redirect}
                    element={
                        <SuspenseWrapper>
                            <SignOutRedirect />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    path={AppRoutes.Login}
                    element={
                        <SuspenseWrapper>
                            <Login />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    path={AppRoutes.Register}
                    element={
                        <SuspenseWrapper>
                            <Register />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    path={AppRoutes.AccountDelete}
                    element={
                        <SuspenseWrapper>
                            <AccountDelete />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    path={AppRoutes.ForgetPassword}
                    element={
                        <SuspenseWrapper>
                            <ForgetPassword />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    path={AppRoutes.AppRedirect}
                    element={
                        <SuspenseWrapper>
                            <AppRedirect />
                        </SuspenseWrapper>
                    }
                />
            </ReactRouterRoutes>
        </Sentry.ErrorBoundary>
    );
};
