import React from 'react';
import { Container } from 'react-bootstrap';

export const Maintainance = () => {
    return (
        <Container>
            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div>
                    <h1>We&rsquo;ll be back soon!</h1>
                    <div>
                        <p>
                            Sorry for the inconvenience but we&rsquo;re
                            performing some maintenance at the moment. If you
                            need to you can always{' '}
                            <a href="mailto:support@studybuddy.gg">
                                contact us
                            </a>
                            , otherwise we&rsquo;ll be back online shortly!
                        </p>
                        <p>&mdash; The Team</p>
                    </div>
                </div>
            </div>
        </Container>
    );
};
